var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.show,
            closable: "",
            type: "info",
            "mask-closable": true,
            title: "立即升级",
            size: "small",
          },
          on: {
            cancel: function ($event) {
              _vm.show = false
            },
          },
        },
        [
          _c("p", [_vm._v("请联系集团管理员进行员工容量升级。")]),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.hideModal } },
                [_vm._v("知道了")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }